import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../lang';
import axios from 'axios'
import api from "@/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: "",
    token: "",
    user: {},
    lang: "",
    assessPermission: [],
    registCheckItem: {  //注册所有选项
    }
  },

  getters: {
    getToken(state: any) {
      return state.token
    },
    getUserId(state: any) {
      return state.id
    },

    getUser(state: any) {
      return (key?: any) => {
        if (key) {
          return state.user[key]
        } else {
          return state.user
        }
      }
    },

    getLang(state: any) {
      return state.lang;
    },

    getAssessPermission(state: any) {
      return state.assessPermission
    },

    getRegistCheckItem(state: any) {
      return (key?: any, data?: []) => {
        if (key) {
          if (data && Array.isArray(data) && data.length > 0) {
            const res = []
            for (const item of data) {
              if ((state.registCheckItem[key])[item])
                state.lang === "en" ? res.push((state.registCheckItem[key])[item].en) : res.push((state.registCheckItem[key])[item].zh_CN)
            }

            return res
          } else return state.registCheckItem[key]
        }
        else
          return state.registCheckItem


      }
    }

    // getFile(state: any) {
    //   return function (id: any) {
    //     return state.fileList.filter((item: any) => item.id === id)[0]
    //   }
    // }
  },

  mutations: {
    setToken(state: any, data: any) {
      state.token = data
    },
    setUserId(state: any, data: any) {
      state.id = data;
    },
    setLang(state: any, data: any) {
      // console.log(`传进store的lang:${data}`)
      state.lang = data;
      // console.log(data)
      i18n.locale = state.lang
      // console.log(`语言改变后的session:${sessionStorage.getItem('lang')}`)
      // console.log(`store:${state.lang}`)
    },

    setUser(state: any, data: any) {
      state.user = data
    },

    setAssessPermission(state: any, data: any) {
      state.assessPermission = data
    },

    setRegistCheckItem(state: any, data: any) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key];
          state.registCheckItem[key] = element;
        }
      }
    }
  },

  actions: {
    getSetToken(context: any, data: any) {
      context.commit('setToken', data)
    },
    getSetUserId(context: any, data: any) {
      context.commit('setUserId', data)
    },
    getSetLang(context: any, data: any) {
      context.commit('setAssessPermission', [])
      context.commit('setLang', data)
    },
    getSetUser(context: any, data: any) {
      context.commit(`setUser`, data)
    },
    getSetAssessPermission(context: any, data: any) {
      context.commit(`setAssessPermission`, data)
    },

    async setRegistCheckItemActions(context: any) {
      const getOpt = api.apiList.getProjectOpt;
      const opt = getOpt();
      await axios({
        method: opt.method,
        url: opt.url,
      }).then(res => {
        context.commit(`setRegistCheckItem`, res.data)
      })
    }



  },
  modules: {
  }
})
