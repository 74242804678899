import i18n from "../lang"
const baseUrl: string = "http://ittn_data.ittn.com.cn/api";

const apiList: any = {

    // 登录
    login() {
        let opt = {
            url: `${baseUrl}/login`,
            method: "post",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //注册
    regist() {
        let opt = {
            url: `${baseUrl}/register`,
            method: "post",
            headers: {
                lang: i18n.locale
            }
        }
        return opt;
    },

    //上传图片
    uploadImg() {
        let opt = {
            url: `${baseUrl}/uploadimage`,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //上传文件
    uploadFile() {
        let opt = {
            url: `${baseUrl}/uploadfile`,
            method: "post",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //新增项目
    uploadProjectBasic() {
        let opt = {
            url: `${baseUrl}/project/${sessionStorage.getItem("id")}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //提交项目技术信息
    uploadProjectTech(pid: any) {
        let opt = {
            url: `${baseUrl}/project_1/${pid}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 提交项目成员信息
    uploadProjectTeam(pid: any) {
        let opt = {
            url: `${baseUrl}/projectteam/${pid}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //提交项目附件信息
    uploadProjectFile() {
        let opt = {
            url: `${baseUrl}/projectfile`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    "api_token"
                )}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取项目注册领域国别等参数 
    getProjectOpt() {
        let opt = {
            url: `${baseUrl}/project_type`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    //获取用户信息
    getUserInfo() {
        let opt = {
            url: `${baseUrl}/user`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    // 根据渠道码获取渠道id
    getChannel(id: any) {
        let opt = {
            url: `${baseUrl}/channel/${id}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //更新项目基本信息
    updateProjectBasic(pid: any) {
        let opt = {
            url: `${baseUrl}/project/${pid}/update`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    /*
     *  更新项目技术信息接口
     *  但本质上等同于添加技术信息，两个接口可以通用
     */
    updateProjectTech(pid: any) {
        let opt = {
            url: `${baseUrl}/project/${pid}/update`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //批量更新项目技术信息
    updateProjectTeam(pid: any) {
        let opt = {
            url: `${baseUrl}/projectteam/${pid}/updatemany`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 删除项目文件
    delProjectFile(id: any) {
        let opt = {
            url: `${baseUrl}/projectfile/${id}/destroy`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 提交专家信息
    uploadExpertBasic() {
        let opt = {
            url: `${baseUrl}/expert`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    // 更新专家信息
    updateExpertBasic(id: any) {
        let opt = {
            url: `${baseUrl}/expert/${id}/update`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取评审权限
    getAssessPermission(eid: any) {
        let opt = {
            url: `${baseUrl}/review/permission/${eid}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },

    //获取评审项目列表
    getAssessList(eid: any, review_id: any) {
        let opt = {
            url: `${baseUrl}/review/list/${eid}/${review_id}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    //获取评审项目
    getAssessProject(record_id: any) {
        // console.log(record_id);

        let opt = {
            url: `${baseUrl}/review/project/${record_id}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    //提交评审分数
    uploadScore(record_id: any) {
        let opt = {
            url: `${baseUrl}/review/project/${record_id}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

    //百强项目领域列表
    projectType() {
        let opt = {
            url: `${baseUrl}/bestechpublish/type`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },
    projectType2023() {
        let opt = {
            url: `${baseUrl}/bestechpublish2022_1/type`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //2021百强项目列表
    projectList_2021(region?: any) {
        let opt = {
            url: `${baseUrl}/bestechpublish/list/${region}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },
    projectList_2023(region?: any) {
        let opt = {
            url: `${baseUrl}/bestechpublish2022/list/${region}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //2021百强项目详情
    projectDetail_2021(pid: any) {
        let opt = {
            url: `${baseUrl}/bestechpublish/detail/${pid}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //2021百强专家列表
    expertList_2021() {
        let opt = {
            url: `${baseUrl}/bestechpublish/expert/list`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    //2021百强专家列表
    expertDetail_2021(eid: any) {
        let opt = {
            url: `${baseUrl}/bestechpublish/expert/detail/${eid}`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    /**2021项目库 */
    allProjectList(country: any, field: any, page: any, keyword?: any) {
        let opt = {
            url: `${baseUrl}/bestechpublishall/list`,
            method: "get",
            headers: {
                lang: i18n.locale
            },
            params: { page: page, country_id: country, field: field, keyword: keyword }
        }
        return opt
    },


    //往届回顾 —— 2020百强项目领域
    field_2020() {
        let opt = {
            url: "http://ittn.com.cn/api/itc_type",
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    projectList_2020(field: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_plist/${field}`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    project_2020(pid: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_pdetail/${pid}`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    expertList_2020() {
        let opt = {
            url: `http://ittn.com.cn/api/itc_elist`,
            method: "get"
        }
        return opt;
    },

    //往届回顾 —— 2020百强项目列表
    expert_2020(eid: any) {
        let opt = {
            url: `http://ittn.com.cn/api/itc_edetail/${eid}`,
            method: "get"
        }
        return opt;
    },

    //获取项目评论
    getComments(id: any) {
        let opt = {
            url: `${baseUrl}/project/comment/${id}`,
            method: "get"
        }
        return opt;
    },

    //提交项目评论
    uploadComments(id: any) {
        let opt = {
            url: `${baseUrl}/project/comment/apply/${id}`,
            method: "post",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt;
    },


    //个人中心获取项目评论
    getProjectComments(id: any) {
        let opt = {
            url: `${baseUrl}/project_comment/${id}`,
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("api_token")}`,
                lang: i18n.locale
            }
        }
        return opt
    },

     //2021百强专家列表
     expertList_2022() {
        let opt = {
            url: `${baseUrl}/bestechpublish2020/expert/list`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },

    // 获取邮箱验证码
    emailCaptha: {
        url: "http://api.gt-itcp.com/api/emailcaptha",
        method: "get"
    },

    // 获取渠道信息
    channel: {
        url: "http://api.gt-itcp.com/api/thirdpartner",
        method: "get"
    },


    //获取2021年百强榜单项目领域分类
    projectType2021() {
        let opt = {
            url: `${baseUrl}/bestechpublish2021/type`,
            method: "get",
            headers: {
                lang: i18n.locale
            }
        }
        return opt
    },


}

export default {
    apiList,
}