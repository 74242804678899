import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: any) {
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: `/home`,
  },
  {
    path: "/container_home",
    name: "container_home",
    component: () => import(/* webpackChunkName: "container" */ "../components/home/Container_Home.vue"),
    children: [
      {
        path: `/:lang?/home`,
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: { webTitle: "首页", noToken: true }
      },
      {
        path: `/:lang?/first_activity`,
        name: "first_activity",
        component: () => import(/* webpackChunkName: "first_activity" */ '../views/FirstActivity.vue'),
        meta: { webTitle: "2020百强活动", noToken: true }
      },
      {
        path: `/:lang?/activity_review_2021`,
        name: "activityReview_2021",
        component: () => import(/* webpackChunkName: "first_activity" */ '../views/ActivityReview_2021.vue'),
        meta: { webTitle: "2021百强活动", noToken: true }
      },
      {
        path: `/:lang?/activity_review_2023`,
        name: "activityReview_2023",
        component: () => import(/* webpackChunkName: "first_activity" */ '../views/ActivityReview_2023.vue'),
        meta: { webTitle: "2023百强活动", noToken: true }
      },
      {
        path: `/:lang?/benefit`,
        name: "benefit",
        component: () => import(/* webpackChunkName: "benefit" */ '../views/ProjectBenefit.vue'),
        meta: { webTitle: "项目权益", noToken: true }
      },
      {
        path: `/:lang?/projectlist2020`,
        name: "projectlist2020",
        component: () => import(/* webpackChunkName: "projectlist2020" */ '../views/ProjectList_2020.vue'),
        meta: { webTitle: "2020百强项目", noToken: true, content: "2020百强榜单", content_en: "2020 \" 100 Best \" Projects" }
      },
      
      {
        path: `/:lang?/project2020/:pid`,
        name: "project2020",
        component: () => import(/* webpackChunkName: "project_2020" */ '../views/Project_2020.vue'),
        meta: { webTitle: "2020百强项目", noToken: true, content: "详情", content_en: "Detail", faterRoute: "projectlist2020" }
      },
      {
        path: `/:lang?/expertlist2020`,
        name: "expertlist2020",
        component: () => import(/* webpackChunkName: "expertlist2020" */ '../views/ExpertList_2020.vue'),
        meta: { webTitle: "2020专家评审", noToken: true, content: "2020专家评审", content_en: "2020 Expert" }
      },
      {
        path: `/:lang?/expert2020/:eid`,
        name: "expert2020",
        component: () => import(/* webpackChunkName: "project_2020" */ '../views/Expert_2020.vue'),
        meta: { webTitle: "2020专家评审", noToken: true, content: "详情", content_en: "Detail", faterRoute: "expertlist2020" }
      },

      {
        path: `/:lang?/projectlist2021`,
        name: "projectlist2021",
        component: () => import(/* webpackChunkName: "projectlist2020" */ '../views/ProjectList_2021.vue'),
        meta: { webTitle: "2021百强项目", noToken: true, content: "2021百强榜单", content_en: "2021 \" 100 Best \" Projects" }
      },
      {
        path: `/:lang?/projectlist2023`,
        name: "projectlist2023",
        component: () => import(/* webpackChunkName: "projectlist2020" */ '../views/ProjectList_2023.vue'),
        meta: { webTitle: "2023百强项目", noToken: true, content: "2023百强榜单", content_en: "2023 \" 100 Best \" Projects" }
      },
      {
        path: `/:lang?/projectlistAll2021`,
        name: "projectlistAll2021",
        component: () => import(/* webpackChunkName: "projectlist2020" */ '../views/ProjectListAll_2021.vue'),
        meta: { webTitle: "百强项目库", noToken: true, content: "百强项目库", content_en: "All Projects" }
      },
      {
        path: `/:lang?/project2021/:pid`,
        name: "project2021",
        component: () => import(/* webpackChunkName: "project_2020" */ '../views/Project_2021.vue'),
        meta: { webTitle: "2021百强项目", noToken: true, content: "详情", content_en: "Detail", faterRoute: "projectlist2021" }
      },
      {
        path: `/:lang?/projectAll2021/:pid`,
        name: "projectAll2021",
        component: () => import(/* webpackChunkName: "project_2020" */ '../views/ProjectAll_2021.vue'),
        meta: { webTitle: "2021项目库", noToken: true, content: "详情", content_en: "Detail", faterRoute: "projectlistAll2021" }
      },
      {
        path: `/:lang?/expertlist2021`,
        name: "expertlist2021",
        component: () => import(/* webpackChunkName: "expertlist2020" */ '../views/ExpertList_2021.vue'),
        meta: { webTitle: "2021专家评审", noToken: true, content: "2021专家评审", content_en: "2021 Expert" }
      },
      {
        path: `/:lang?/expert2021/:eid`,
        name: "expert2021",
        component: () => import(/* webpackChunkName: "project_2020" */ '../views/Expert_2021.vue'),
        meta: { webTitle: "2021专家评审", noToken: true, content: "详情", content_en: "Detail", faterRoute: "expertlist2021" }
      },
      {
        path: "/:lang?/zgc",
        name: "ZGCView",
        component: () => import(/* webpackChunkName: "ZGCView" */ '../views/ZGCView.vue'),
        meta: { webTitle: "中关村论坛", noToken: true, content: "中关村论坛", content_en: "ZGC Forum " }
      },
      {
        path: `/:lang?/expertlist2022`,
        name: "expertlist2022",
        component: () => import(/* webpackChunkName: "expertlist2020" */ '../views/ExpertList_2022.vue'),
        meta: { webTitle: "2022专家评审", noToken: true, content: "2022专家评审", content_en: "2022 Expert" }
      },
    ]
  },
  {
    path: "/container",
    name: "container",
    component: () => import(/* webpackChunkName: "container" */ "../components/Container.vue"),
    children: [
      {
        path: `/:lang?/login/:from?`,
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: { webTitle: "登录" }
      },
      {
        path: "/:lang?/register/:step?/:pid?",
        name: "register-plain",
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
      },
      {
        path: "/:lang?/regist/:step/:pid?",
        name: "register",
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
      },
      {
        path: "/:lang?/regsit-expert/:step/:eid?",
        name: "register-expert",
        component: () => import(/* webpackChunkName: "login" */ '../views/Register_expert.vue'),
        meta: { webTitle: "专家注册" }
      },

      {
        path: "/:lang?/usercenter",
        name: "userCenter",
        component: () => import(/* webpackChunkName: "userCenter" */"../views/UserCenter.vue")
      },
      {
        path: "/:lang?/about",
        name: "about",
        component: () => import(/* webpackChunkName: "login" */ '../views/About.vue'),
        meta: { webTitle: "登录" }
      },
      {
        path: "/assess",
        name: "assess",
        component: () => import(/* webpackChunkName: "assess" */"../views/assess/Assess.vue"),
        meta: { content: { zh: "项目评审", en: "Project Evaluation" } },
        children: [
          {
            path: "/assessList/:eid/:id",
            name: "assessList",
            component: () => import(/* webpackChunkName: "assessList" */"../views/assess/AssessList.vue"),
            meta: { content: { zh: "项目列表", en: "Project List" } },
          },
          {
            /**record_id:评分id
             * isFinished:是否评审完毕
             */
            path: "/score/:record_id/:isFinished",
            name: "score",
            component: () => import(/* webpackChunkName: "score" */"../views/assess/Score.vue"),
            meta: { content: { zh: "详情", en: "Detail" } },

          }
        ]
      },
      // {
      //   path: "/score",
      //   name: "score",
      //   component: () => import(/* webpackChunkName: "score" */"../views/assess/Score.vue")
      // }
    ]
  },
  {
    path: "/containerUser",
    name: "containerUser",
    component: () => import(/* webpackChunkName: "containerUser" */ "../components/Container_User.vue"),
    children: [
      {
        path: "/userinfo",
        name: "userinfo",
        component: () => import(/* webpackChunkName: "userinfo" */"../views/UserInfo.vue")
      },
      {
        path: "/projectlist",
        name: "projectlist",
        component: () => import(/* webpackChunkName: "userinfo" */"../views/project/ProjectList.vue")
      },
      {
        path:'/projectComments/:pid',
        name:'projectComments',
        props:true,
        component:() => import(/* webpackChunkName: "projectComments" */"../views/project/ProjectCommentsView.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import(/* webpackChunkName: "project" */"../views/project/Project.vue"),
        children: [
          {
            path: "/projectBasic/:pid?",
            name: "projectBasic",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/project/ProjectBasic.vue")
          },
          {
            path: "/projectTech/:pid?",
            name: "projectTech",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/project/ProjectTech.vue")
          },
          {
            path: "/projectTeam/:pid?",
            name: "projectTeam",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/project/ProjectTeam.vue")
          },
          {
            path: "/projectFile/:pid?",
            name: "projectFile",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/project/ProjectFile.vue")
          },
          {
            path: "/editProject/:step/:pid",
            name: "editProject",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/project/Edit.vue")
          },
        ]
      },
      {
        path: "/expert",
        name: "expert",
        component: () => import(/* webpackChunkName: "expert" */"../views/expert/Expert.vue"),
        children: [
          {
            path: "/expertBasic/:eid?",
            name: "expertBasic",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/expert/ExpertBasic.vue")
          },
          {
            path: "/editExpert/:step/:eid",
            name: "editExpert",
            component: () => import(/* webpackChunkName: "userinfo" */"../views/expert/Edit.vue")
          },
        ]
      },

    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
export default router
